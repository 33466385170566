import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { api } from "../../utils/Api";
import DataContext from "../../utils/Context/DataContext";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  Entypo,
  Ionicons,
} from "@expo/vector-icons";
import toast from "react-hot-toast";
import { LineWeight } from "@material-ui/icons";
import "react-alice-carousel/lib/alice-carousel.css";
import AliceCarousel from "react-alice-carousel";
import UpdateVariantDetailModal from "./UpdateVariantDetailModal";
import ModalMrp from "./ModalMrp";
import ModalSpecification from "./ModalSpecification";
import ModalAddGallery from "../Gallery/ModalAddGallery";
import ModalDeleteConfirm from "../Products/ModalDeleteConfirm";
import { AppHelper } from "../../utils/Helper";
import ModalCentralized from "./ModalCentralized";

const handleDragStart = (e) => e.preventDefault();

const responsive = {
  0: { items: 1 },
  200: { items: 1 },
  300: { items: 1 },
  350: { items: 1 },
  548: { items: 2 },
  720: { items: 2 },
  1024: { items: 2 },
};

export default function ProductVariantDetail(props: any) {
  const context = useContext(DataContext);
  const [id_value, SetIdValue] = useState(useParams());

  const [product_group_data, SetProductGroupData] = useState({});
  const [variant_data, SetVariantData] = useState({});
  const [image_base_url, SetImageBaseUrl] = useState("");
  const [image_sizes, SetImageSizes] = useState([]);
  const [page_no, SetPageNo] = useState(1);
  const [sort_by, SetSortBy] = useState("desc");
  const [img_show, SetImgShow] = useState("");
  const [var_spec_data, SetVarSpecData] = useState([]);

  const [option_data, SetOptionsData] = useState({});

  // local image
  const [img, SetImg] = useState([
    {
      src: require("../../assets/img/1.jpeg"),
    },
    {
      src: require("../../assets/img/2.jpeg"),
    },
    {
      src: require("../../assets/img/3.jpeg"),
    },
    {
      src: require("../../assets/img/4.jpeg"),
    },
  ]);
  const [modal_update_variant_details, SetModalUpdateVariantDetails] = useState(
    {
      is_open: false,
      variant_data: {},
      option_data: {},
    }
  );
  const [modal_mrp, SetModalMrp] = useState({
    is_open: false,
    data: "",
    variant_index: "",
  });
  const [modal_spec, SetModalSpec] = useState({
    is_open: false,
    data: [],
  });

  const [modal_gallery, SetModalGallery] = useState({
    is_open: false,
    data: {},
    type: "select",
  });

  const [modal_centralized, SetModalCentralized] = useState({
    is_open: false,
    data: {},
  });
  

  // const [is_edit, SetIsEdit] = useState(false)
  const [is_edit, SetIsEdit] = useState(true);

  const [modal_delete_confirm, SetModalDeleteConfirm] = useState({
    is_open: false,
    data: {},
  });

  useEffect(() => {
    console.log("ProductVariantDetail On mount :", props);
    console.log("ProductVariantDetail On mount context :", context);
    let pass_data = {
      page_no: page_no,
      sort_by: sort_by,
    };
    get_details(pass_data);
  }, []);

  async function get_details(pass_value) {
    let pass_data = {
      get: {
        variant_id: id_value.product_variant_id,
        // page: pass_value.page_no,
        // sort: pass_value.sort_by,
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    let response = await api("/product/product_variant_details", pass_data);
    console.log("/product/product_variant_details response :", response);
    if (response.status_code === 200) {
      if (response.response.hasOwnProperty("options")) {
        SetOptionsData(response.response.options);
      }
      if (response.response.hasOwnProperty("product_group")) {
        SetProductGroupData(response.response.product_group);
      }
      if (response.response.hasOwnProperty("image_sizes")) {
        SetImageSizes(response.response.image_sizes);
      }
      if (response.response.hasOwnProperty("image_base_url")) {
        SetImageBaseUrl(response.response.image_base_url);
      }
      if (response.response.hasOwnProperty("data")) {
        let v_data = response.response.data;
        // v_data.map((ele: any) => {
        //   ele.expand = false;
        // });
        v_data.expand = true;
        SetVariantData(v_data);
        if(v_data.hasOwnProperty("specifications")){
          SetVarSpecData(v_data.specifications);
        }else{
          SetVarSpecData([]);
        }
      }
      if (response.response.next_page) {
        SetPageNo((prevValue: any) => {
          prevValue = prevValue + 1;
          return prevValue;
        });
      }
    }
  }

  function isArray(obj) {
    return Object.prototype.toString.call(obj) === "[object Array]";
  }

  async function remove_addon_group(pass_value) {
    let pass_data = {
      get: {},
      post: {
        addons: [pass_value.a_ele.id],
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    console.log("remove_variant_addon_group pass_data :", pass_data);
    let response = await api("/product/remove_variant_addon_group", pass_data);
    console.log("/product/remove_variant_addon_group response :", response);
    if (response.status_code == 200) {
      if (response.response.status) {
        toast.success("Addon Group Removed");

        SetVariantData((prevValue: any) => {
          prevValue[pass_value.key].splice(pass_value.a_index, 1);
          console.log(
            "data on click :",
            prevValue[pass_value.v_index][pass_value.key]
          );

          return { ...prevValue };
        });
      }
    }
  }

  async function remove_addon_item(pass_value) {
    let pass_data = {
      get: {},
      post: {
        addons: [pass_value.i_ele.id],
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    console.log("remove_variant_addon_items pass_data :", pass_data);
    let response = await api("/product/remove_variant_addon_items", pass_data);
    console.log("/product/remove_variant_addon_items response :", response);
    if (response.status_code == 200) {
      if (response.response.status) {
        toast.success("Addon Item Removed");
        SetVariantData((prevValue: any) => {
          prevValue[pass_value.key][pass_value.a_index].items.splice(
            pass_value.i_index,
            1
          );
          return { ...prevValue };
        });
      }
    }
  }

  async function product_remove_addon_group(pass_value) {
    let pass_data = {
      get: {},
      post: {
        addons: [pass_value.a_ele.id],
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    console.log("remove_product_addon_group pass_data :", pass_data);
    let response = await api("/product/remove_product_addon_group", pass_data);
    console.log("/product/remove_product_addon_group response :", response);
    if (response.status_code == 200) {
      if (response.response.status) {
        toast.success("Addon Group Removed");
        let call_data = {
          page_no: page_no,
          sort_by: sort_by,
        };
        get_details(call_data);
      }
    }
  }

  async function product_remove_addon_item(pass_value) {
    let pass_data = {
      get: {},
      post: {
        addons: [pass_value.i_ele.id],
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    console.log("remove_product_addon_items pass_data :", pass_data);
    let response = await api("/product/remove_product_addon_items", pass_data);
    console.log("/product/remove_product_addon_items response :", response);
    if (response.status_code == 200) {
      if (response.response.status) {
        toast.success("Addon Item Removed From Group");
        let call_data = {
          page_no: page_no,
          sort_by: sort_by,
        };
        get_details(call_data);
      }
    }
  }

  async function remove_mrp(get_data) {
    let pass_data = {
      get: {},
      post: {
        mrp: [get_data],
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    console.log("/product/delete_product_mrp pass_data :", pass_data);
    let response = await api("/product/delete_product_mrp", pass_data);
    console.log("/product/delete_product_mrp response :", response);
    if (response.status_code == 200) {
      if (response.response.status) {
        toast.success("Mrp removed successfully");
        let call_data = {
          page_no: page_no,
          sort_by: sort_by,
        };
        get_details(call_data);
      }
    }
  }

  async function add_mrp(get_data) {
    let pass_data = {
      get: {},
      post: {
        variant_id: id_value.product_variant_id,
        mrp: get_data.value,
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    console.log("/product/add_product_mrp pass_data :", pass_data);
    let response = await api("/product/add_product_mrp", pass_data);
    console.log("/product/add_product_mrp response :", response);
    if (response.status_code == 200) {
      if (response.response.status) {
        toast.success("Mrp Added successfully");
        let call_data = {
          page_no: page_no,
          sort_by: sort_by,
        };
        get_details(call_data);
      }
    }
  }

  async function add_image() {
    let pass_data = {
      get: {},
      post: {
        variant_id: id_value.product_variant_id,
        images: [],
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    console.log("/product/add_variant_images pass_data :", pass_data);
    let response = await api("/product/add_variant_images", pass_data);
    console.log("/product/add_variant_images response :", response);
  }

  async function remove_image(img_index: any) {
    let pass_data = {
      get: {},
      post: {
        variant_id: id_value.product_variant_id,
        images: [variant_data.images[img_index].id],
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    console.log("/product/remove_variant_images pass_data :", pass_data);
    let response = await api("/product/remove_variant_images", pass_data);
    console.log("/product/remove_variant_images response :", response);
    if (response.status_code == 200) {
      if (response.response.status) {
        let call_data = {
          page_no: page_no,
          sort_by: sort_by,
        };
        get_details(call_data);
      }
    }
  }

  async function add_variant_images(get_data) {
    let pass_data = {
      get: {},
      post: {
        variant_id: id_value.product_variant_id,
        images: get_data.image,
      },
    };
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }
    console.log("/product/add_variant_images pass_data :", pass_data);
    let response = await api("/product/add_variant_images", pass_data);
    console.log("/product/add_variant_images response :", response);
    if (response.status_code == 200) {
      if (response.response.status) {
        let call_data = {
          page_no: page_no,
          sort_by: sort_by,
        };
        get_details(call_data);
      }
    }
  }

  function get_updated_spec(get_data : any){
    
    if(variant_data.hasOwnProperty("specifications_updates")){
      // let is_updated_index =  variant_data.specifications_updates.findIndex((ele : any) => ele.type == get_data.type_id);
      let is_updated_index =  variant_data.specifications_updates.findIndex((ele : any) => ele.specifications_id == get_data.specifications_id);
      if(is_updated_index != -1){
        return (
          <>
            {get_data.type_id == "1" ? (
              <div className="px-2">
                <p className="v-small text-darkgray mb-2">
                  <Entypo
                    name="dot-single"
                    size={16}
                    color="black"
                  />
                  {variant_data.specifications_updates[is_updated_index].value}
                </p>
                <p>It's Under Review</p>
              </div>
            ) : null}
            {get_data.type_id == "2" ? (
              <div className="px-2">
                <p className="v-small text-darkgray mb-2">
                  <Entypo
                    name="dot-single"
                    size={16}
                    color="black"
                  />
                  {variant_data.specifications_updates[is_updated_index].value.name}
                </p>
                <p>It's Under Review</p>
              </div>
            ) : null}
            {get_data.type_id == "3" ? (
              <div className="px-2">
                {variant_data.specifications_updates[is_updated_index].value.map(
                  (variant_data: any, v_index) => (
                    <p
                      key={v_index}
                      className="v-small text-darkgray mb-2"
                    >
                      <Entypo
                        name="dot-single"
                        size={16}
                        color="black"
                      />
            
                      {variant_data.name}
                    </p>
                  )
                )}
                <p>It's Under Review</p>
              </div>
            ) : null}
            {get_data.type_id == "4" ? (
              <div className="px-2">
                <p className="v-small text-darkgray mb-2">
                  <Entypo
                    name="dot-single"
                    size={16}
                    color="black"
                  />
                  {variant_data.specifications_updates[is_updated_index].value}
                </p>
              </div>
            ) : null}
          </>
        )
      }else{
        return (
          <>
            {get_data.type_id == "1" ? (
              <div className="px-2">
                <p className="v-small text-darkgray mb-2">
                  <Entypo
                    name="dot-single"
                    size={16}
                    color="black"
                  />
                  {get_data.value}
                </p>
              </div>
            ) : null}
            {get_data.type_id == "2" ? (
              <div className="px-2">
                <p className="v-small text-darkgray mb-2">
                  <Entypo
                    name="dot-single"
                    size={16}
                    color="black"
                  />
                  {get_data.value.name}
                </p>
              </div>
            ) : null}
            {get_data.type_id == "3" ? (
              <div className="px-2">
                {get_data.value.map(
                  (variant_data: any, v_index) => (
                    <p
                      key={v_index}
                      className="v-small text-darkgray mb-2"
                    >
                      <Entypo
                        name="dot-single"
                        size={16}
                        color="black"
                      />
            
                      {variant_data.name}
                    </p>
                  )
                )}
              </div>
            ) : null}
            {get_data.type_id == "4" ? (
              <div className="px-2">
                <p className="v-small text-darkgray mb-2">
                  <Entypo
                    name="dot-single"
                    size={16}
                    color="black"
                  />
                  {get_data.value}
                </p>
              </div>
            ) : null}
          </>
        )
      }
    }else{
      return (
        <>
          {get_data.type_id == "1" ? (
            <div className="px-2">
              <p className="v-small text-darkgray mb-2">
                <Entypo
                  name="dot-single"
                  size={16}
                  color="black"
                />
                {get_data.value}
              </p>
            </div>
          ) : null}
          {get_data.type_id == "2" ? (
            <div className="px-2">
              <p className="v-small text-darkgray mb-2">
                <Entypo
                  name="dot-single"
                  size={16}
                  color="black"
                />
                {get_data.value.name}
              </p>
            </div>
          ) : null}
          {get_data.type_id == "3" ? (
            <div className="px-2">
              {get_data.value.map(
                (variant_data: any, v_index) => (
                  <p
                    key={v_index}
                    className="v-small text-darkgray mb-2"
                  >
                    <Entypo
                      name="dot-single"
                      size={16}
                      color="black"
                    />
          
                    {variant_data.name}
                  </p>
                )
              )}
            </div>
          ) : null}
          {get_data.type_id == "4" ? (
            <div className="px-2">
              <p className="v-small text-darkgray mb-2">
                <Entypo
                  name="dot-single"
                  size={16}
                  color="black"
                />
                {get_data.value}
              </p>
            </div>
          ) : null}
        </>
      )
    }
    
   
  }

  function Badge({ label, bgColor = "#007BFF", textColor = "#FFFFFF" } : any) {
    const badgeStyle = {
      backgroundColor: bgColor,
      color: textColor,
      padding: "5px 10px",
      borderRadius: "20px",
      fontSize: "12px",
      fontWeight: "bold",
      display: "inline-block",
      width:"100%",
      textAlign:"center",
      margin:"2"
    };
  
    return <span style={badgeStyle} className="badge w-50 bg-success text-uppercase">{label}</span>;
  }

  return (
    <div className="">
      {/* Header */}
      <div className=" row align-items-center head-bar">
        <div className="d-flex align-items-center py-3">
          <div
            className="cursor"
            onClick={() => {
              window.history.back();
            }}
          >
            <Ionicons name="arrow-back-sharp" size={18} color="#222" />
          </div>
          <div className="col-md-4 col-7 ps-2 mb-0">
            <h5 className=" mb-0">Product Detail</h5>
          </div>
          
          <div className="ms-auto">
            {/* {!is_edit ? (
              <button className='btn btn-sm btn-primary px-3'
                onClick={() => {
                  SetIsEdit(true)
                }}
              >
                Edit
              </button>
            ) : (
              <button className='btn btn-sm btn-secondary px-3'
                onClick={() => {
                  SetIsEdit(false)
                }}
              >
                Cancel
              </button>
            )} */}
          </div>
        </div>
      </div>

      {/* Body */}
      <div className="container py-3 px-sm-0 px-md-3">
        {/* <p>Variants Details</p> */}
        <div className="bg-fff shadow mb-3">
          {/* <div className="p-3"
          onClick={() => {
            SetVariantData((prevValue: any) => {
              prevValue.expand = !prevValue.expand;
              return { ...prevValue };
            });
          }}
          >
          </div> */}
          {variant_data.expand ? (
            <div className="p-3">
              <div className="row pb-3">
                <div className="col-md-5">
                  {/* <AliceCarousel
                    mouseTracking
                    touchMoveDefaultEvents={false}
                    // items={productsRestraunt}
                    // paddingLeft={50}
                    paddingRight={50}
                    disableDotsControls
                    responsive={responsive}
                    disableButtonsControls
                    controlsStrategy="alternate"
                  >
                    {img.map((data, index) => (
                      <div
                        className=""
                        key={index}
                        onDragStart={handleDragStart}
                      >
                        <div className="px-1">
                          <img src={data.src} className="details_img" />
                        </div>
                      </div>
                    ))}
                  </AliceCarousel> */}

                  <div className="row">
                    {/* <AliceCarousel
                      mouseTracking
                      touchMoveDefaultEvents={false}
                      // items={productsRestraunt}
                      // paddingLeft={50}
                      paddingRight={50}
                      disableDotsControls
                      responsive={responsive}
                      disableButtonsControls
                      controlsStrategy="alternate"
                    >
                      {variant_data.images?.map((img_ele: any, img_index) => (
                        <div
                          className=""
                          key={img_index}
                          onDragStart={handleDragStart}
                        >
                          <div className="px-1">
                            <img
                              src={
                                image_base_url +
                                image_sizes[0] +
                                '/' +
                                img_ele.url
                              }
                              alt=""
                              width="100%"
                              height="100%"
                              className="details_img"
                            />
                          </div>
                          {is_edit ? (
                            <div className="img_over_content">
                              <button
                                className="text-white mb-0 btn btn-sm d-flex align-items-center"
                                onClick={() => {
                                  console.log('Remove images')
                                  remove_image(img_index)
                                }}
                              >
                                <div className="pe-1">
                                  <MaterialIcons
                                    name="delete-outline"
                                    size={16}
                                    color="#fff"
                                  />
                                </div>
                                Remove
                              </button>
                            </div>
                          ) : null}
                        </div>
                      ))}
                    </AliceCarousel> */}
                    {/* Image */}
                    <div className="swiper scrollX d-flex px-2 h-170">
                      {variant_data.hasOwnProperty("images") ? (
                        variant_data.images?.map((img_ele: any, img_index) => (
                          <div
                            className="col-md-6 col-12 px-1"
                            key={img_index}
                            onDragStart={handleDragStart}
                          >
                            <div className="px-1">
                              <img
                                src={AppHelper.imageSrc(
                                  image_base_url,
                                  img_ele.url,
                                  {
                                    format: "webp",
                                    width: 320,
                                  }
                                )}
                                // src={
                                //   image_base_url +
                                //   image_sizes[0] +
                                //   "/" +
                                //   img_ele.url
                                // }
                                alt=""
                                width="100%"
                                height="100%"
                                className="details_img"
                              />
                            </div>
                            {is_edit ? (
                              <div className="img_over_content">
                                <button
                                  className="text-white mb-0 btn btn-sm d-flex align-items-center"
                                  onClick={() => {
                                    console.log("Remove images");
                                    remove_image(img_index);
                                  }}
                                >
                                  <div className="pe-1">
                                    <MaterialIcons
                                      name="delete-outline"
                                      size={16}
                                      color="#fff"
                                    />
                                  </div>
                                  Remove
                                </button>
                              </div>
                            ) : null}
                          </div>
                        ))
                      ) : (
                        <img
                          src={require("../../assets/img/no-image.jpg")}
                          className=""
                        />
                      )}
                    </div>
                    <div className="center mt-3 w-100">
                      {is_edit ? (
                        <button
                          className="btn w-75  shadow-btn bg-fff"
                          onClick={() => {
                            console.log("add image");
                            SetModalGallery((prevValue: any) => {
                              prevValue.is_open = true;
                              return { ...prevValue };
                            });
                          }}
                        >
                          <span className="pe-1">
                            <MaterialCommunityIcons
                              name="image-multiple-outline"
                              size={16}
                              color="black"
                            />
                          </span>
                          Add Images
                        </button>
                      ) : null}
                      {!id_value.hasOwnProperty("brand_id") && (
                        variant_data.centralized ? (
                          <button
                            className="btn w-75 m-2 shadow-btn bg-fff"
                            onClick={() => {
                              SetModalCentralized((prevValue: any) => {
                                prevValue.is_open = true;
                                prevValue.data = {
                                  type : "uncentralized",
                                  from : "product"
                                }
                                return { ...prevValue };
                              });
                            }}
                          >
                            Make Uncentralized
                          </button>
                        ) : (
                          <button
                            className="btn w-75 m-2 shadow-btn bg-fff"
                            onClick={() => {
                              SetModalCentralized((prevValue: any) => {
                                prevValue.is_open = true;
                                prevValue.data = {
                                  type : "centralized",
                                  from : "product"
                                }
                                return { ...prevValue };
                              });
                            }}
                          >
                            Make Centralized
                          </button>
                        )
                      )}

                    </div>
                  </div>
                </div>
                {/* Basic */}
                <div className="col-md-7 pt-sm-2 pt-md-0">
                  {Object.keys(variant_data.update_request_basic).length > 0 ? (
                    <>
                      <h6 className="">{variant_data.update_request_basic.name}</h6>
                      <p>(It's Under Review)</p>
                    </>
                  ) : (
                    <h6 className="">{variant_data.name}</h6>
                  )}
                  {/* <p className="mb-0 pt-2 v-small">{variant_data.description}</p> */}

                  {/* {variant_data.status == "Active" ? "Available": "Un-Available"} */}
                  <p className="mb-0 text-gray v-small pt-2">
                    Brand :{" "}
                    <b className="text-dark">{variant_data.product_brand}</b>
                  </p>
                  <p className="mb-0 text-gray v-small pt-2">
                    Category :{" "}
                    <b className="text-dark">{variant_data.category}</b>
                  </p>
                  <div className="d-flex align-items-center pt-2">
                    <p className=" mb-0">
                      <small className="fw-500">
                        Status :
                        <span className="text-dark ps-1">
                          {variant_data.status}
                        </span>
                      </small>
                    </p>
                    <div className="end">
                      <Entypo name="language" size={13} color="#777" />
                      <small className="text-gray fw-500 ps-1">
                        {variant_data.orgin_language}
                      </small>
                    </div>
                  </div>
                  {variant_data.centralized && (
                    <div className="pt-2">
                      <Badge label="centralized" bgColor="#28A745" />
                    </div>
                  )}
                  {Object.keys(variant_data.update_request_basic).length > 0 ? (
                    <>
                      <p className="pt-3 mb-0">
                        <small className="fw-500">{variant_data.description}</small>
                      </p>
                      <p>(It's Under Review)</p>
                    </>
                  ) : (
                    <p className="pt-3 mb-0">
                      <small className="fw-500">{variant_data.description}</small>
                    </p>
                  )}
                  
                </div>
              </div>

              {/* Product Details */}
              <div className="border-top py-3">
                <div className="d-flex align-items-center mb-3">
                  <h6 className="p-2 ps-0 mb-0">Product Details</h6>
                  {is_edit ? (
                    <div className="ms-auto">
                      <button
                        className="btn btn-sm text-primary"
                        onClick={() => {
                          SetModalUpdateVariantDetails((prevValue: any) => {
                            prevValue.is_open = true;
                            prevValue.variant_data = variant_data;
                            prevValue.option_data = option_data;
                            return { ...prevValue };
                          });
                        }}
                      >
                        Update
                      </button>
                    </div>
                  ) : null}
                </div>
                {/* <div className="px-2">
                  {variant_data.weight != '' ? (
                    <div className="row p-0 border-top">
                      <div className="col-5 bg-light p-0 px-2 border-right">
                        <p className="mb-0 text-dark p-2">Weight</p>
                      </div>
                      <div className="col-7 p-0">
                        <p className="text-gray mb-0 p-2">
                          {variant_data.weight +
                            ' ' +
                            variant_data.weight_unit_name}
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {variant_data.product_brand != '' ? (
                    <div className="row p-0 border-top">
                      <div className="col-5 bg-light p-0 px-2 border-right">
                        <p className="mb-0 text-dark p-2">Brand</p>
                      </div>
                      <div className="col-7 p-0">
                        <p className="text-gray mb-0 p-2">
                          {variant_data.product_brand}
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {variant_data.package_length != '' &&
                    variant_data.package_breadth !== '' ? (
                    <div className="row p-0 border-top">
                      <div className="col-5 bg-light p-0 px-2 border-right">
                        <p className="mb-0 text-dark p-2">
                          Length & Breath & Height
                        </p>
                      </div>
                      <div className="col-7 p-0">
                        <p className="text-gray mb-0 p-2">
                          {variant_data.package_length +
                            variant_data.package_length_unit_name}
                          ,
                          {variant_data.package_breadth +
                            variant_data.package_breadth_unit_name}
                          ,
                          {variant_data.package_height +
                            variant_data.package_height_unit_name}
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {variant_data.orgin_country != '' ? (
                    <div className="row p-0 border-top">
                      <div className="col-5 bg-light p-0 px-2 border-right">
                        <p className="mb-0 text-dark p-2">Country of Orgin</p>
                      </div>
                      <div className="col-7 p-0">
                        <p className="text-gray mb-0 p-2">
                          {variant_data.orgin_country}
                        </p>
                      </div>
                    </div>
                  ) : null}
                </div> */}

                <div className="px-2">
                  {variant_data.description != "" &&
                  variant_data.description != null ? (
                    <div className="row p-0 border-top">
                      <div className="col-5 bg-light p-0 px-2 border-right">
                        <p className="mb-0 text-dark p-2">Description</p>
                      </div>
                      <div className="col-7 p-0">
                        <p className="text-gray mb-0 p-2 v-small">
                          {variant_data.description}
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {variant_data.orgin_country != "" &&
                  variant_data.orgin_country != null ? (
                    <div className="row p-0 border-top">
                      <div className="col-5 bg-light p-0 px-2 border-right">
                        <p className="mb-0 text-dark p-2">Country of Orgin</p>
                      </div>
                      <div className="col-7 p-0">
                        <p className="text-gray mb-0 p-2">
                          {variant_data.orgin_country}
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {variant_data.product_brand != "" &&
                  variant_data.product_brand != null ? (
                    <div className="row p-0 border-top">
                      <div className="col-5 bg-light p-0 px-2 border-right">
                        <p className="mb-0 text-dark p-2">Brand</p>
                      </div>
                      <div className="col-7 p-0">
                        <p className="text-gray mb-0 p-2">
                          {variant_data.product_brand}
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {variant_data.hsn != "" && variant_data.hsn != null ? (
                    <div className="row p-0 border-top">
                      <div className="col-5 bg-light p-0 px-2 border-right">
                        <p className="mb-0 text-dark p-2">HSN</p>
                      </div>
                      <div className="col-7 p-0">
                        <p className="text-gray mb-0 p-2 v-small">
                          {variant_data.hsn}
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {variant_data.gst != "" && variant_data.gst != null ? (
                    <div className="row p-0 border-top">
                      <div className="col-5 bg-light p-0 px-2 border-right">
                        <p className="mb-0 text-dark p-2">GST</p>
                      </div>
                      <div className="col-7 p-0">
                        <p className="text-gray mb-0 p-2 v-small">
                          {variant_data.gst}
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {variant_data.gtin != "" && variant_data.gtin != null ? (
                    <div className="row p-0 border-top">
                      <div className="col-5 bg-light p-0 px-2 border-right">
                        <p className="mb-0 text-dark p-2">GTIN</p>
                      </div>
                      <div className="col-7 p-0">
                        <p className="text-gray mb-0 p-2 v-small">
                          {variant_data.gtin}
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {variant_data.upc != "" && variant_data.upc != null ? (
                    <div className="row p-0 border-top">
                      <div className="col-5 bg-light p-0 px-2 border-right">
                        <p className="mb-0 text-dark p-2">UPC</p>
                      </div>
                      <div className="col-7 p-0">
                        <p className="text-gray mb-0 p-2 v-small">
                          {variant_data.upc}
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {variant_data.ean != "" && variant_data.ean != null ? (
                    <div className="row p-0 border-top">
                      <div className="col-5 bg-light p-0 px-2 border-right">
                        <p className="mb-0 text-dark p-2">EAN</p>
                      </div>
                      <div className="col-7 p-0">
                        <p className="text-gray mb-0 p-2 v-small">
                          {variant_data.ean}
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {variant_data.jan != "" && variant_data.jan != null ? (
                    <div className="row p-0 border-top">
                      <div className="col-5 bg-light p-0 px-2 border-right">
                        <p className="mb-0 text-dark p-2">JAN</p>
                      </div>
                      <div className="col-7 p-0">
                        <p className="text-gray mb-0 p-2 v-small">
                          {variant_data.jan}
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {variant_data.isbn != "" && variant_data.isbn != null ? (
                    <div className="row p-0 border-top">
                      <div className="col-5 bg-light p-0 px-2 border-right">
                        <p className="mb-0 text-dark p-2">ISBN</p>
                      </div>
                      <div className="col-7 p-0">
                        <p className="text-gray mb-0 p-2 v-small">
                          {variant_data.isbn}
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {variant_data.mpn != "" && variant_data.mpn != null ? (
                    <div className="row p-0 border-top">
                      <div className="col-5 bg-light p-0 px-2 border-right">
                        <p className="mb-0 text-dark p-2">MPN</p>
                      </div>
                      <div className="col-7 p-0">
                        <p className="text-gray mb-0 p-2 v-small">
                          {variant_data.mpn}
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {variant_data.min_handling_time != "" &&
                  variant_data.min_handling_time != null ? (
                    <div className="row p-0 border-top">
                      <div className="col-5 bg-light p-0 px-2 border-right">
                        <p className="mb-0 text-dark p-2">Min Handling Time</p>
                      </div>
                      <div className="col-7 p-0">
                        <p className="text-gray mb-0 p-2 v-small">
                          {variant_data.min_handling_time}
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {variant_data.max_handling_time != "" &&
                  variant_data.max_handling_time != null ? (
                    <div className="row p-0 border-top">
                      <div className="col-5 bg-light p-0 px-2 border-right">
                        <p className="mb-0 text-dark p-2">Max Handling Time</p>
                      </div>
                      <div className="col-7 p-0">
                        <p className="text-gray mb-0 p-2 v-small">
                          {variant_data.max_handling_time}
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {(variant_data.length != "" && variant_data.length != null) ||
                  (variant_data.width != "" && variant_data.width != null) ||
                  (variant_data.height != "" && variant_data.height != null) ? (
                    <div className="row p-0 border-top">
                      <div className="col-5 bg-light p-0 px-2 border-right">
                        <p className="mb-0 text-dark p-2">
                          Length / Width / Height
                        </p>
                      </div>
                      <div className="col-7 p-0">
                        <p className="text-gray mb-0 p-2 v-small">
                          {variant_data.length + " " + variant_data.length_unit}{" "}
                          / {variant_data.width + " " + variant_data.width_unit}{" "}
                          /{" "}
                          {variant_data.height + " " + variant_data.height_unit}
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {variant_data.weight != "" && variant_data.weight != null ? (
                    <div className="row p-0 border-top">
                      <div className="col-5 bg-light p-0 px-2 border-right">
                        <p className="mb-0 text-dark p-2">Weight</p>
                      </div>
                      <div className="col-7 p-0">
                        <p className="text-gray mb-0 p-2">
                          {variant_data.weight +
                            " " +
                            variant_data.weight_unit_name}
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {variant_data.package_length != "" &&
                  variant_data.package_length != null ? (
                    <div className="row p-0 border-top">
                      <div className="col-5 bg-light p-0 px-2 border-right">
                        <p className="mb-0 text-dark p-2">Package Length</p>
                      </div>
                      <div className="col-7 p-0">
                        <p className="text-gray mb-0 p-2">
                          {variant_data.package_length +
                            " " +
                            variant_data.package_length_unit_name}
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {variant_data.package_breadth != "" &&
                  variant_data.package_breadth != null ? (
                    <div className="row p-0 border-top">
                      <div className="col-5 bg-light p-0 px-2 border-right">
                        <p className="mb-0 text-dark p-2">Package Breadth</p>
                      </div>
                      <div className="col-7 p-0">
                        <p className="text-gray mb-0 p-2">
                          {variant_data.package_breadth +
                            " " +
                            variant_data.package_breadth_unit_name}
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {variant_data.package_height != "" &&
                  variant_data.package_height != null ? (
                    <div className="row p-0 border-top">
                      <div className="col-5 bg-light p-0 px-2 border-right">
                        <p className="mb-0 text-dark p-2">Package Height</p>
                      </div>
                      <div className="col-7 p-0">
                        <p className="text-gray mb-0 p-2">
                          {variant_data.package_height +
                            " " +
                            variant_data.package_height_unit_name}
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {variant_data.package_weight != "" &&
                  variant_data.package_weight != null ? (
                    <div className="row p-0 border-top">
                      <div className="col-5 bg-light p-0 px-2 border-right">
                        <p className="mb-0 text-dark p-2">Package Weight</p>
                      </div>
                      <div className="col-7 p-0">
                        <p className="text-gray mb-0 p-2">
                          {variant_data.package_weight +
                            " " +
                            variant_data.package_weight_unit_name}
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {/* {variant_data.specifications.map((spec_ele:any,spec_index:any)=>(
                    <div className="row p-0 border-top">
                      <div className="col-5 bg-light p-0 px-2 border-right">
                        <p className="mb-0 text-dark p-2">{spec_ele.name}</p>
                      </div>
                      <div className="col-7 p-0">
                        <p className="text-gray mb-0 p-2 v-small">
                          {spec_ele.value.map((variant_data:any)=>(
                            variant_data.name + ","
                          ))}
                        </p>
                      </div>
                    </div>
                  ))} */}
                </div>
              </div>

              {/* Specification */}
              <div className="py-3 border-top">
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">Specification</h6>
                  {is_edit ? (
                    <div className="ms-auto">
                      <button
                        className="btn btn-sm text-primary"
                        onClick={() => {
                          SetModalSpec((prevValue: any) => {
                            const spec_data = var_spec_data;
                            prevValue.is_open = true;
                            prevValue.data = spec_data;

                            return { ...prevValue };
                          });
                        }}
                      >
                        Update
                      </button>
                    </div>
                  ) : null}
                </div>
                {Object.entries(variant_data).map(([key, value]) =>
                  variant_data[key] != null ? (
                    isArray(variant_data[key]) ? (
                      key == "specifications" ? (
                        // border-top pt-3
                        <div className="" key={key}>
                          {/* {key == 'mrp' ? (
                            <div className="table-responsive p-md-2 p-sm-0">
                              <div className="d-flex my-3 align-items-center">
                                <h6 className="mb-0">MRP Details</h6>
                                {is_edit ? (
                                  <div className="ms-auto">
                                    <button
                                      className="btn btn-sm text-primary"
                                      onClick={() => {
                                        SetModalMrp((prevValue: any) => {
                                          prevValue.is_open = true
                                          // prevValue.variant_index = v_index
                                          return { ...prevValue }
                                        })
                                      }}
                                    >
                                      Add Mrp
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                              <table className="table teble-hover pad-table">
                                <thead className="bg-light p-2 border-bottom">
                                  <th>Country</th>
                                  <th>State</th>
                                  <th>Currency</th>
                                  <th>Mrp</th>
                                  {is_edit ? <th>Action</th> : null}
                                </thead>
                                <tbody>
                                  {variant_data[key].map(
                                    (mrp_ele: any, mrp_index) => (
                                      <tr key={mrp_index}>
                                        <td>{mrp_ele.country_name}</td>
                                        <td>{mrp_ele.state_name}</td>
                                        <td>
                                          {mrp_ele.currency} -{' '}
                                          {mrp_ele.currency_name}
                                        </td>
                                        <td>{mrp_ele.mrp}</td>
                                        {is_edit ? (
                                          <td>
                                            <button
                                              className="btn btn-sm"
                                              onClick={() => {
                                                remove_mrp(mrp_ele.id)
                                              }}
                                            >
                                              <MaterialIcons
                                                name="delete-outline"
                                                size={16}
                                                color="gray"
                                              />
                                            </button>
                                          </td>
                                        ) : null}
                                      </tr>
                                    ),
                                  )}
                                </tbody>
                              </table>
                            </div>
                          ) : null} */}

                          {/* {key == "group_variant_specification" ? (
                            <div className="">
                              <table>
                                <thead>
                                  <th>#</th>
                                  <th>Spec Id</th>
                                  <th>Name</th>
                                </thead>
                                <tbody>
                                  {variant_data[key].map(
                                    (spec_ele: any, spec_index) => (
                                      <tr key={spec_index}>
                                        <td>{spec_index + 1}</td>
                                        <td>{spec_ele.spec_id}</td>
                                        <td>{spec_ele.name}</td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          ) : null} */}

                          <div className="">
                              <div className="row px-3">
                                {variant_data[key].map(
                                  (spec1_ele: any, spec1_index: any) => (
                                    <div
                                      key={spec1_index}
                                      className="col-md-3 pb-3 px-2"
                                    >
                                      <div className="card p-2">
                                        <p className="fw-bold text-dark mb-2 p-1 pb-0">
                                          {spec1_ele.name}
                                        </p>
                                        {get_updated_spec(spec1_ele)}
                                        {/* {spec1_ele.type_id == "1" ? (
                                          <div className="px-2">
                                            <p className="v-small text-darkgray mb-2">
                                              <Entypo
                                                name="dot-single"
                                                size={16}
                                                color="black"
                                              />
                                              {spec1_ele.value}
                                            </p>
                                          </div>
                                        ) : null}
                                        {spec1_ele.type_id == "2" ? (
                                          <div className="px-2">
                                            <p className="v-small text-darkgray mb-2">
                                              <Entypo
                                                name="dot-single"
                                                size={16}
                                                color="black"
                                              />
                                              {spec1_ele.value.name}
                                            </p>
                                          </div>
                                        ) : null}
                                        {spec1_ele.type_id == "3" ? (
                                          <div className="px-2">
                                            {spec1_ele.value.map(
                                              (variant_data: any, v_index) => (
                                                <p
                                                  key={v_index}
                                                  className="v-small text-darkgray mb-2"
                                                >
                                                  <Entypo
                                                    name="dot-single"
                                                    size={16}
                                                    color="black"
                                                  />

                                                  {variant_data.name}
                                                </p>
                                              )
                                            )}
                                          </div>
                                        ) : null}
                                        {spec1_ele.type_id == "4" ? (
                                          <div className="px-2">
                                            <p className="v-small text-darkgray mb-2">
                                              <Entypo
                                                name="dot-single"
                                                size={16}
                                                color="black"
                                              />
                                              {spec1_ele.value}
                                            </p>
                                          </div>
                                        ) : null} */}
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                          </div>
                        </div>
                      ) : null
                    ) : typeof variant_data[key] == "object" ? null : null
                  ) : null
                )}
              </div>

              {/* mrp */}
              <div className="py-3 border-top">
                <div className="table-responsive p-md-2 p-sm-0">
                  <div className="d-flex align-items-center">
                    <h6 className="mb-0">MRP Details</h6>
                    {is_edit ? (
                      <div className="ms-auto">
                        <button
                          className="btn btn-sm text-primary"
                          onClick={() => {
                            SetModalMrp((prevValue: any) => {
                              prevValue.is_open = true;
                              // prevValue.variant_index = v_index
                              return { ...prevValue };
                            });
                          }}
                        >
                          Add Mrp
                        </button>
                      </div>
                    ) : null}
                  </div>
                  {variant_data.hasOwnProperty("mrp") ? (
                    <table className="table teble-hover pad-table">
                      <thead className="bg-light p-2 border-bottom">
                        <th>Country</th>
                        <th>State</th>
                        <th>Currency</th>
                        <th>Mrp</th>
                        {is_edit ? <th>Action</th> : null}
                      </thead>
                      <tbody>
                        {variant_data.mrp.length > 0 ? (
                          variant_data.mrp.map((mrp_ele: any, mrp_index) => (
                            <tr key={mrp_index}>
                              <td>{mrp_ele.country_name}</td>
                              <td>{mrp_ele.state_name}</td>
                              <td>
                                {mrp_ele.currency} - {mrp_ele.currency_name}
                              </td>
                              <td>{mrp_ele.mrp}</td>
                              {is_edit ? (
                                <td>
                                  <button
                                    className="btn btn-sm"
                                    onClick={() => {
                                      // remove_mrp(mrp_ele.id)

                                      SetModalDeleteConfirm(
                                        (prevValue: any) => {
                                          prevValue.is_open = true;
                                          prevValue.data = {
                                            title: "Remove Mrp",
                                            body: "Are you sure want to remove",
                                            pass_value: mrp_ele.id,
                                            for: "mrp",
                                          };
                                          return { ...prevValue };
                                        }
                                      );
                                    }}
                                  >
                                    <MaterialIcons
                                      name="delete-outline"
                                      size={16}
                                      color="gray"
                                    />
                                  </button>
                                </td>
                              ) : null}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td>No Data</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  ) : (
                    <div className="text-center p-2">
                      <p>No Mrp Details!</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <ModalDeleteConfirm
        open={modal_delete_confirm.is_open}
        data={modal_delete_confirm.data}
        close={(data: any) => {
          SetModalDeleteConfirm((prevValue: any) => {
            prevValue.is_open = false;
            prevValue.data = {};
            return { ...prevValue };
          });
          if (data.action == "save") {
            if (data.for == "mrp") {
              remove_mrp(data.pass_value);
            }
            // if(data.for == "image"){
            //   remove_group_image(data.pass_value)

            // }
            // if(data.for == "addon_group"){
            //   product_remove_addon_group(data.pass_value)
            // }
            // if(data.for == "variant_addon_group_item"){
            //   remove_addon_item(data.pass_value)
            // }

            // if(data.for == "variant_addon_group"){
            //   remove_addon_group(data.pass_value)
            // }
          }
        }}
      />

      <UpdateVariantDetailModal
        open={modal_update_variant_details.is_open}
        edit_data={modal_update_variant_details.variant_data}
        option_data={modal_update_variant_details.option_data}
        close={(data) => {
          SetModalUpdateVariantDetails((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {
            toast.success("Product Details Updated");
            let pass_data = {
              page_no: page_no,
              sort_by: sort_by,
            };
            get_details(pass_data);
          }
        }}
      />

      <ModalMrp
        open={modal_mrp.is_open}
        // variant_index={modal_mrp.variant_index}
        variant_data={variant_data}
        close={(data: any) => {
          console.log("ModalMrp on close data : ", data);
          // console.log("variant_data :", data.variant_data[data.variant_index]);
          SetModalMrp((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {
            add_mrp(data);
          }
        }}
      />

      <ModalSpecification
        open={modal_spec.is_open}
        category_id={variant_data.category_id}
        selected_spec_data={modal_spec.data}
        close={(data) => {
          SetModalSpec((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {
            toast.success("Specification Updated");
            // let pass_data = {
            //   page_no: page_no,
            //   sort_by: sort_by,
            // }
            // get_details(pass_data)
          }
          let pass_data = {
            page_no: page_no,
            sort_by: sort_by,
          };
          get_details(pass_data);
        }}
      />

      <ModalAddGallery
        open={modal_gallery.is_open}
        type={modal_gallery.type}
        data={modal_gallery.data}
        from={"product_detail"}
        close={(data: any) => {
          console.log("ModalAddGallery on close :", data);
          SetModalGallery((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {
            // get_gallery(1)

            let send_data = {
              image: [],
            };
            data.value.map((ele: any) => {
              send_data.image.push(ele.id);
            });

            add_variant_images(send_data);
          }
        }}
      />

      <ModalCentralized 
        open = {modal_centralized.is_open}
        data = {modal_centralized.data}
        close={(data : any)=>{
          SetModalCentralized((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if(data.action == "save"){
            let pass_data = {
              page_no: page_no,
              sort_by: sort_by,
            };
            get_details(pass_data);
          }
        }}
      />
    </div>
  );
}
